@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply text-primary placeholder-gray-300;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
    -webkit-tap-highlight-color: transparent;
    --swiper-pagination-color: #fba919;
}

@layer base {
    h1 {
        @apply text-2xl sm:text-3xl md:text-4xl 3xl:text-5xl 3xl:leading-tight font-serif text-brand;
    }
    h2 {
        @apply text-2xl md:text-3xl font-serif;
    }
    h3 {
        @apply text-xl font-serif;
    }
    h4 {
        @apply text-lg lg:text-xl;
    }
    h5 {
        @apply text-sm sm:text-base font-bold;
    }

    input {
        @apply placeholder-opacity-50 placeholder-gray-500;
    }

    #regions-map-svg .region-group:hover path {
        @apply fill-gray cursor-pointer transition;
    }

    #regions-map-svg text {
        @apply pointer-events-none;
    }

    img[src=''] {
        @apply invisible;
    }

    .poi-description a {
        @apply underline font-medium;
    }

    .poi-description ul {
        @apply list-disc pl-8 marker:text-brand marker:font-bold space-y-1;
    }

    .poi-description ol {
        @apply list-decimal pl-8;
    }

    .autocomplete-item {
        @apply border-b border-gray-300 last:border-none;
    }

    .autocomplete-item[aria-selected='true'] {
        @apply bg-gray-200 cursor-pointer;
    }

    mark {
        @apply bg-brand-light font-semibold;
    }

    .border {
        @apply border-solid;
    }

    .article-content {
        @apply space-y-3;
    }

    .article-content p a {
        @apply text-brand underline;
    }

    .article-content > p {
        @apply mt-3 mb-8;
    }

    .article-content > h2 {
        @apply pt-10;
    }

    .article-content > h3,
    .article-content > h4,
    .article-content > h5 {
        @apply pt-5;
    }

    .article-content > ul {
        @apply list-disc pl-4 ml-4 marker:text-brand;
    }

    .article-content > blockquote {
        @apply bg-brand-dull p-8 rounded-3xl text-center font-bold;
    }

    .article-content .scroll-anchor {
        display: block;
        position: relative;
        top: -80px;
        visibility: hidden;
    }
}

@layer utilities {
    .text-shadow-sm {
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }

    .white-gradient-left {
        background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, white 50%);
    }

    .white-gradient-right {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 50%);
    }
}
/* Hide default X icon in search inputs */
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
}

.fb_dialog.fb_dialog_mobile {
    z-index: 49 !important;
}

/* FB chat plugin positions */
@media (max-width: 768px) {
    .fb_dialog_content iframe.fb_customer_chat_icon {
        margin: 0 !important;
        right: 5px !important;
    }
}

.finalist-photo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    grid-auto-flow: dense;
    gap: 28px;
    justify-content: center;
}

.photo-card {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-column-end: span 3;
}

.photo-card.landscape {
    grid-column-end: span 4;
}

.photo-card.portrait {
    grid-column-end: span 3;
}

.hide-scrollbars {
    scroll-behavior: smooth;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
}

.hide-scrollbars::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.__react_component_tooltip {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 12px !important;
    background: #3f3f46 !important;
    line-height: 1;
}

.__react_component_tooltip.place-left {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #f68920;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

.mapboxgl-popup-content {
    padding: 0;
    border-radius: 20px;
    overflow: hidden;
}

/* Swiper styles */

.swiper-container .swiper-button-next,
.swiper-container .swiper-button-prev {
    color: white;
}

.swiper-container .swiper-button-next:after,
.swiper-container .swiper-button-prev:after {
    font-size: 1.5rem;
}

.swiper-container .swiper-button-next:hover,
.swiper-container .swiper-button-prev:hover {
    color: #fba919;
}

.swiper-container .swiper-pagination-bullet {
    background: white;
    opacity: 0.4;
    transition: all 0.4s ease;
    width: 6px;
    height: 6px;
}

.swiper-container .swiper-pagination-bullet.swiper-pagination-bullet-active {
    opacity: 1;
}
body .swiper-container .swiper-pagination-bullets {
    bottom: 3px;
}

#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #29d;

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    bottom: 15px;
    right: 15px;
}

#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: #fba919;
    border-left-color: #fba919;
    border-radius: 50%;

    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
